<template>
	<div>
		<div id="mapSwitch" class="row d-block d-md-none" align="center">
			<div class="col-12">
				<button
					class="switchBtn"
					style="border-radius: 0 4px 4px 0;"
					:class="{
						on: isSatelite,
					}"
					@click="
						toggleMapStyle(
							'hamidkazemzadeh/ckq2sozke25hf17qh16mdszbh'
						)
					"
				>
					ماهواره
				</button>
				<button
					class="switchBtn"
					:class="{
						on: !isSatelite,
					}"
					style="border-radius: 4px 0 0 4px;"
					@click="
						toggleMapStyle(
							'hamidkazemzadeh/ckqgm1p580ctf17o2kalvw6wu'
						)
					"
				>
					نقشه
				</button>
			</div>
		</div>
		<div class="row d-none d-md-block mapSwitcMd" align="center">
				<button
					class="switchBtn"
					style="border-radius: 0 4px 4px 0;"
					:class="{
						on: isSatelite,
					}"
					@click="
						toggleMapStyle(
							'hamidkazemzadeh/ckq2sozke25hf17qh16mdszbh'
						)
					"
				>
					ماهواره
				</button>
				<button
					class="switchBtn"
					:class="{
						on: !isSatelite,
					}"
					style="border-radius: 4px 0 0 4px;"
					@click="
						toggleMapStyle(
							'hamidkazemzadeh/ckqgm1p580ctf17o2kalvw6wu'
						)
					"
				>
					نقشه
				</button>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["isSatelite"],

		methods: {
			toggleMapStyle(style) {
				this.$emit("switchMap", style);
			},
		},
	};
</script>

<style scoped>
	.mapSwitcMd {
    position: absolute;
    left: 65px;
    bottom: 20px;
    z-index: 1;
	}

	.switchBtn.on {
		background-color: #f5f5f5;
    min-width: 5.2rem;
		border: 1px solid #f5f5f5;
		box-shadow: none;
		padding: 0.4rem;
		margin-top: 0.5rem;
	}
	.switchBtn {
		background-color: #ccc;
		min-width: 5.2rem;
		border: 1px solid #ccc;
		box-shadow: 0;
		padding: 0.4rem;
		margin-top: 0.5rem;
	}
	html,
	body {
		overflow: hidden;
	}
</style>
