<template>
  <div class="m-0 p-0 position-relative z-2">
    <div class="d-flex items-center position-relative">
      <CInput
        class="m-0 w-100"
        placeholder="جستجو..."
        v-model.lazy="search"
        @focus="
								search
									? (isSuggestShow = true)
									: (isSuggestShow = false)
							"
        @keyup.enter="getocodeLocate"
        @input="getocodeLocate"
      />
        <CIcon
          @click="search = null"
          :disabled="!search"
          name="cilSearch" class="search-icon position-absolute"/>
    </div>
    <CRow
      class="search-items"
      v-show="isSuggestShow && search"
    >
      <CCol col="12" class="p-0">
        <CCard>
          <!-- <CCardHeader>
            <CButton
              variant="ghost"
              @click="isSuggestShow = false"
            >
              <CIcon name="cil-x" />
            </CButton>

          </CCardHeader> -->
          <CCardBody class="p-0">
            <CListGroup>
              <CListGroupItem
                tag="button"
                v-for="(suggest,
								index) in geocodeSuggestions"
                :key="index"
                @click="
									emitCoordinates(
										suggest.boundingbox
									);
									isSuggestShow = false;
								"
              >
                {{ suggest.display_name }}
              </CListGroupItem>
            </CListGroup>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      search: null,
      geocodeSuggestions: [],
      isSuggestShow: false,
    };
  },
  // watch: {
  // 	search() {
  // 		this.getocodeLocate();
  // 	},
  // },
  methods: {
    wait(ms) {
      var start = new Date().getTime();
      var end = start;
      while (end < start + ms) {
        end = new Date().getTime();
      }
    },
    async getocodeLocate() {
      await axios
        .get(
          `https://nominatim.openstreetmap.org/search.php?q=${this.search}&format=jsonv2&accept-language=fa-IR&countrycodes=IR`
        )
        .then((res) => {
          this.geocodeSuggestions = res.data;

          if (this.geocodeSuggestions.length > 0) {
            this.isSuggestShow = true;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    print() {
      alert("done");
    },
    emitCoordinates(boundingbox) {
      // const coords = { lat: lat, lng: lon };
      this.$emit("selectedGeocode", boundingbox);
    },
  },
};
</script>

<style>
.search-icon {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.search-items{
  overflow-y: auto;
  right: 0;
  position: absolute;
  left: 0;
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
  height: 300px;
}
</style>
